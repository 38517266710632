import React, { useState } from "react";
import TrustPayments from "./TrustPayment";
import { useHistory } from "react-router-dom";

export default function TrustPaymentLayer(props) {
  const [jwt, setJwt] = useState(false);
  const [loader, setLoader] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState({
    status: false,
    message: "",
  });

  const [userPaymentId, setUserPyamentId] = useState(false);
  const [userPaymentCard, setUserPyamentCard] = useState();
  let history = useHistory();
  const handleSavedCardSelect = (e) => {
    setUserPyamentId(e.target.value);
    setUserPyamentCard(
      props.trustCardDetails.find(
        (item) => item.user_payment_id == e.target.value
      ).card_number
    );
  };
  const getJwt = async () => {
    const data = await props.submit().then((res) => {
      setJwt(res.token);
    });
  };
  const submitFunction = (data) => {
      props.submit(userPaymentId).then((res) => {
          console.log(res)
     })
    // setPaymentComplete((prev) => ({
    //   ...prev,
    //   status: true,
    //   message:
    //     data.errorcode === "0" ? "Payment Successfull" : "Some Error Occured",
    // }));
    // if (data.errorcode === "0") {
    //   setTimeout(() => {
    //     history.push("/dashboard");
    //   }, 3000);
    // }
  };
  return (
    <>
      {paymentComplete.status ? (
        <div>{paymentComplete.message}</div>
      ) : (
        <div>
          {!jwt ? (
            <>
              <label htmlFor="car">Select Card </label>
              <div className="form-row" style={{ maxWidth: "500px" }}>
                <div className="input-group mb-6">
                  <select
                    className="custom-select"
                    id="inputGroupSelect01"
                    onChange={handleSavedCardSelect}
                  >
                    <option selected>Select Card</option>
                    {props.trustCardDetails &&
                      props.trustCardDetails.map((card) => (
                        <option key={card.id} value={card.user_payment_id}>
                          {"Card Number with " + card.card_number}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="item buttons">
                <button
                  className="pay-button"
                  onClick={submitFunction}
                  disabled={loader}
                >
                  {loader ? (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  ) : (
                    ""
                  )}
                  Proceed
                </button>
              </div>{" "}
            </>
          ) : (
            <>
              <br />
              <TrustPayments
                submit={submitFunction}
                jwt={jwt}
                fieldsToSubmit={[]}
                buttonText={"Pay"}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
